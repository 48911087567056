<template>
  <el-dialog title="无限级精准压裂滑套系统" :visible.sync="dialogVisible" width="60%">
    <div id="img-div">
      <el-image style="height: 200px" src="images/scroll_new_broke.jpg"/>
    </div>
    <div id="main-content-div">
      <div id="sub-content-div">
        <p id="title-p">产品简介</p>
        <p id="sub-p">一种可以改变现有压裂工艺、降低施工规模、实现数字井底、优化井身结构、提高最终采收率的系统。</p>
      </div>
      <div id="sub-content-div">
        <p id="title-p">产品特性</p>
        <p id="sub-p">● 提高施工效率</p>
        <p id="sub-p">● 提高投产效率、压裂后无需通井作业即可排采投产</p>
        <p id="sub-p">● 提高安全性，更少的人和设备、更少的施工环节</p>
        <p id="sub-p">● 提高单井最终可采储量EUR</p>
        <p id="sub-p">● 降低了场地要求，没有过多的人员和设备进场</p>
        <p id="sub-p">● 降低了施工排量的同时提高了压裂规模</p>
        <p id="sub-p">● 降低了施工摩阻的同时也降低了井口施工压力</p>
        <p id="sub-p">● 降低了套变几率，连续不停泵施工</p>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    props: ['info'],
    data () {
      return {
        dialogVisible: false,
      }
    },
    watch: {
      info: {
        handler: function () {
          this.dialogVisible = true
        },
        deep: true
      }
    },
    mounted () {

    },
    methods: {
    }
  }
</script>
<style>
#main-content-div{

}
#img-div{
  display: flex;
  align-items: center;
}
#title-p{
  font-weight: bold;
  font-size: 16px;
}
#sub-p{
  font-size: 13px;
}
</style>
