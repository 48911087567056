<template>
  <el-dialog title="量子除垢装置" :visible.sync="dialogVisible" width="60%">
    <div id="img-div">
      <el-image style="height: 200px" src="images/scroll_chugou.jpg"/>
    </div>
    <div id="main-content-div">
      <div id="sub-content-div">
        <p id="title-p">产品简介</p>
        <p id="sub-p">量子阻垢装置是专门用于改善工业循环水水质，阻垢、除垢、除锈效果显著。该装置不需通电、不需添加化学药剂、不需人员维护值守。</p>
      </div>
      <div id="sub-content-div">
        <p id="title-p">产品特性</p>
        <p id="sub-p">● 显著阻垢除垢</p>
        <p id="sub-p">● 功效持久稳定</p>
        <p id="sub-p">● 节能节水节省费用</p>
      </div>
      <div id="sub-content-div">
        <p id="title-p">产品应用</p>
        <p id="sub-p">● 锅炉、热交换器等加热设备</p>
        <p id="sub-p">● 石油、冶金、电力、化工、制药、造纸、食品及饮料加工等领域的循环水系统</p>
        <p id="sub-p">● 建筑物中央空调系统</p>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    props: ['info'],
    data () {
      return {
        dialogVisible: false,
      }
    },
    watch: {
      info: {
        handler: function () {
          this.dialogVisible = true
        },
        deep: true
      }
    },
    mounted () {

    },
    methods: {
    }
  }
</script>
<style>
#main-content-div{

}
#img-div{
  display: flex;
  align-items: center;
}
#title-p{
  font-weight: bold;
  font-size: 16px;
}
#sub-p{
  font-size: 13px;
}
</style>
