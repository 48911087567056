<template>
  <div id="content-main-div" >
    <p id="content-title-span">北京信佳伟业科技发展有限公司</p>
    <span id="content-sub-title-span">专业高效，值得信赖</span>
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {

    }
  },
}
</script>

<style>
#content-main-div{
  width: 100%;
  height: 800px;
  background-image: url('~/public/images/scroll_big_2.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#content-text-div{
  background-color: cyan
}
#content-title-span{
  /* margin: 15px 20px 15px 20px; */
  font-weight: bold;
  font-size: 65px;
  color: white;
  opacity: 0.9;
  padding-top: 60px;
}
#content-sub-title-span{
  /* margin: 0px 20px 15px 20px; */
  font-weight: bold;
  font-size: 40px;
  color: white;
  opacity: 0.9;
}
</style>
