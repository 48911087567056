<template>
  <div id="banner-main-div">
    <div id="banner-title-div">
      <p id="banner-title-p">主营业务</p>
    </div>
    <el-carousel type="card" height="300px;">
      <el-carousel-item v-for="obj in items" :key="obj.title">
        <div id="img-div" @click="click(obj)">
          <span id="img-div-title">{{obj.title}}</span>
          <el-image style="width: 450px; height: 280px" :src="obj.url"/>
        </div>
      </el-carousel-item>
    </el-carousel>
    <Intro :info="mwdObj"/>
    <Yalie :info="yalieObj"/>
    <Chugou :info="chugouObj"/>
  </div>
</template>

<script>
import Intro from '../dialog/_mwdIntro.vue'
import Yalie from '../dialog/_yalieIntro.vue'
import Chugou from '../dialog/_chugouIntro.vue'
export default {
  components: {
    Intro,
    Yalie,
    Chugou
  },
  data () {
    return {
      items: [
        {
          url: 'images/scroll_new_mwd.webp',
          title: 'MWD仪器',
          type: 'mwd'
        },
        {
          url: 'images/scroll_new_broke.jpg',
          title: '无限级精准压裂滑套系统',
          type: 'broke'
        },
        {
          url: 'images/scroll_chugou.jpg',
          title: '量子除垢装置',
          type: 'chugou'
        },
        {
          url: 'images/scroll_zhiduanhuatao.jpg',
          title: '趾端滑套',
          type: 'huatao'
        },
      ],
      mwdObj: {
        status: 0,
        data: {}
      },
      yalieObj: {
        status: 0,
        data: {}
      },
      chugouObj: {
        status: 0,
        data: {}
      }
    }
  },
  methods: {
    click: function (obj) {
      if (obj.type === 'mwd') {
        this.mwdObj = {
          status: Math.random(),
          data: obj
        }
      }
      if (obj.type === 'broke') {
        this.yalieObj = {
          status: Math.random(),
          data: obj
        }
      }
      if (obj.type === 'chugou') {
        this.chugouObj = {
          status: Math.random(),
          data: obj
        }
      }
    }
  }
}
</script>

<style>
#banner-title-div{
  margin: 0px;
  display: flex;
  justify-content: center;
}
#banner-title-p{
  font-weight: bold;
  font-size: 30px;
  margin: 15px;
  opacity: 0.6;
}
#img-div{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#img-div-title{
  font-weight: bold;
  font-size: 20px;
  opacity: 0.6;
}
</style>
