<template>
  <div id="intro-main-div" >
    <!-- <p id="intro-title-p">关于凯孚</p> -->
    <span id="intro-sub-title-span">北京信佳伟业科技发展有限公司，于2012年成立于北京，是集高端石油技术的研发、生产、销售于一体的高新技术企业</span>
    <span id="intro-sub-title-span">公司主营业务包括：MWD仪器、压裂工具、高效PDC钻头、钻井定制化解决方案等</span>
    <span id="intro-sub-title-span">邮箱：xjwy753313@126.com</span>
    <span id="intro-sub-title-span">地址：北京市昌平区流村镇黑寨村临95号</span>
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {

    }
  },
}
</script>

<style>
#intro-title-p{
  font-weight: bold;
  font-size: 30px;
  margin: 15px;
  color: white;
  opacity: 0.9;
}
#intro-main-div{
  width: 100%;
  height: 350px;
  background-image: url('~/public/images/bottom_big.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#intro-text-div{

}
#intro-sub-title-span{
  margin: 0px 20px 15px 20px;
  font-weight: bold;
  font-size: 20px;
  color: white;
  opacity: 0.9;
}
</style>
