<template>
  <div id="home-main-div">
    <Content/>
    <Banner/>
    <!-- <Mwd/> -->
    <Intro/>
  </div>
</template>

<script>
import Banner from './banner/_banner.vue'
// import Mwd from './banner/_mwd.vue'
import Content from './content/_content.vue'
import Intro from './content/_intro.vue'
export default {
  name: 'HomePage',
  components: {
    Banner,
    // Mwd,
    Content,
    Intro
  },
  mounted() {
    document.title = '信佳伟业'
  }
}
</script>

<style>
</style>
