<template>
  <el-dialog title="MWD仪器" :visible.sync="dialogVisible" width="60%">
    <div id="img-div">
      <el-image style="height: 200px" src="images/mwd_intro.webp"/>
    </div>
    <div id="main-content-div">
      <div id="sub-content-div">
        <p id="title-p">产品简介</p>
        <p id="sub-p">这是一种坐键式可打捞的正脉冲无线随钻测斜仪，做到了井口投放井底打捞，测量准确、使用方便。仪器采用了经实践检验成熟可靠的正泥浆脉冲技术，并在地面部分使用无线电传输立管压力信号及其它数据参数，使用时不需要布置电缆，方便简洁。</p>
      </div>
      <div id="sub-content-div">
        <p id="title-p">产品特性</p>
        <p id="sub-p">● 可打捞正脉冲无线随钻测斜仪</p>
        <p id="sub-p">● 地面设备无线通信，无需布置电缆</p>
        <p id="sub-p">● 模块化设计，方便更新及维护</p>
        <p id="sub-p">● 采用高精度传感器组件</p>
        <p id="sub-p">● 可选配自然伽马及井深测量系统</p>
      </div>
      <div id="sub-content-div">
        <p id="title-p">产品应用</p>
        <p id="sub-p">● 石油钻井工程</p>
        <p id="sub-p">● 煤矿施工</p>
        <p id="sub-p">● 隧道及非开挖穿越施工</p>
        <p id="sub-p">● 小井眼定向井/水平井施工</p>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    props: ['info'],
    data () {
      return {
        dialogVisible: false,
      }
    },
    watch: {
      info: {
        handler: function () {
          this.dialogVisible = true
        },
        deep: true
      }
    },
    mounted () {

    },
    methods: {
    }
  }
</script>
<style>
#main-content-div{

}
#img-div{
  display: flex;
  align-items: center;
}
#title-p{
  font-weight: bold;
  font-size: 16px;
}
#sub-p{
  font-size: 13px;
}
</style>
